import config from "@/config";
import Cookies from "js-cookie";
import Config from "@/config";
import bakCategoryAnimation1 from "@/views/Category/animations/bak.category-animation-1.vue";
import basketFormDeliveryTypes from "@/views/Basket/basket-form-delivery-types.vue";

export default {
    computed: {
        currentCity() {
            return this.$store.state.cityName;
        },
    },
    methods: {
        getProductAvailability(product, section) {
            if (!product || !section) return true;
            if (this.currentCity === config.CITIES[0].name) {
                if (product.NO_SPB || Number(section.UF_NO_SPB)) return false;
            } else if (this.currentCity === config.CITIES[1].name) {
                if (product.NO_MSK || Number(section.UF_NO_MSK)) return false;
            } else {
                if (product.NO_MSK || Number(section.UF_NO_MSK) || product.NO_SPB || Number(section.UF_NO_SPB) || product.NO_CDEK) return false;
            }
            return true;
        },
        getAvailableInCityCategories(categories) {
            if (!categories || !this.currentCity) return null;
            if (this.currentCity === config.CITIES[1].name) {
                return categories.filter(category => (!Number(category.UF_NO_MSK) && !Number(category.UF_NO_SPB)) || Number(category.UF_NO_SPB));
            } else if (this.currentCity === config.CITIES[0].name) {
                return categories.filter(category => (!Number(category.UF_NO_MSK) && !Number(category.UF_NO_SPB)) || Number(category.UF_NO_MSK));
            } else {
                return categories;
            }
        },
        checkBasketItemsAvailability(basket) {
            if (!basket) return null;
            return basket.map(basketItem => {
                if (!basketItem.product) {
                    console.warn('Отсутствует информация о продукте ' + basketItem?.NAME);
                    return {
                        ...basketItem,
                        isProductAvailable: true,
                    }
                }
                const section = this.$store.state.common.sections.find(el => el.ID === basketItem.product?.IBLOCK_SECTION_ID);
                const isProductAvailable = this.getProductAvailability(basketItem.product, section);

                return {
                    ...basketItem,
                    isProductAvailable,
                }
            })
        },
        getUnavailableBasketItemsCount(basket) {
            if (!basket) return null;
            let counter = 0;
            basket.forEach(basketItem => {
                if (!basketItem.product) {
                    console.warn('Отсутствует информация о продукте ' + basketItem?.NAME);
                    return;
                }
                const section = this.$store.state.common.sections.find(el => el.ID === basketItem.product?.IBLOCK_SECTION_ID);
                const isProductAvailable = this.getProductAvailability(basketItem.product, section);
                if (!isProductAvailable) counter++;
            });
            return counter;
        },
    },
    created() {
        const isCityConfirmed = localStorage.getItem('isCityConfirmed');
        if (isCityConfirmed) {
            if (Cookies.get('cityName') && localStorage.getItem('cityId') === Config.CITIES[2].id &&
                (Cookies.get('cityName') === Config.CITIES[0].name || Cookies.get('cityName') === Config.CITIES[1].name)) {
                this.$store.commit('setCurrentCity', {cityId: Config.CITIES[2].id, cityName: Config.CITIES[2].name});
                return;
            }
            if (Cookies.get('cityName') && localStorage.getItem('cityId') === Config.CITIES[2].id &&
                (Cookies.get('cityName') !== Config.CITIES[0].name || Cookies.get('cityName') !== Config.CITIES[1].name)) {
                this.$store.commit('setCurrentCity', {cityId: localStorage.getItem('cityId'), cityName: Cookies.get('cityName')});
                return;
            }
            this.$store.commit('setCurrentCity', {cityId: localStorage.getItem('cityId'), cityName: localStorage.getItem('cityName')});
        } else {
            if (!Cookies.get('cityName')) {
                this.$store.commit('setCurrentCity', {cityId: Config.CITIES[0].id, cityName: Config.CITIES[0].name});
                localStorage.setItem('cityId', Config.CITIES[0].id);
                localStorage.setItem('cityName', Config.CITIES[0].name);
                return;
            }

            this.$store.commit('setCurrentCity', {cityId: Cookies.get('cityId'), cityName: Cookies.get('cityName')});
            localStorage.setItem('cityId', Cookies.get('cityId'));
            localStorage.setItem('cityName', Cookies.get('cityName'));
        }
    },
}